import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

import { Container } from 'react-bootstrap';
import { HeadElement } from '../components/global/HeadElement';

function NotFoundPage() {
   const [t] = useTranslation('404');

   return (
      <>
         <HeadElement namespace="index" />
         <Container>
            <div className="py-4">
               <div>
                  <h1>{t('title1')}</h1>
               </div>
               <div>{t('title2')}</div>
               <Link to="/">{t('action')}</Link>.
            </div>
         </Container>
      </>
   );
}

export default NotFoundPage;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
   }
`;
